@import "~bootstrap/dist/css/bootstrap.min.css";

/* Custom CSS styles */
body {
  background: #000;
  color: #fff;
}

.container {
  margin-top: 20px;
}

.show-card {
  background: #222;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.show-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.card-img-top {
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.card-text {
  font-size: 14px;
  opacity: 0.8;
  line-height: 1.4;
  color: #ccc;
}

.btn-primary {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  border-color: transparent;
  border-radius: 25px;
}

.btn-primary:hover {
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  border-color: transparent;
}
.form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    color: #fff;
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }